import Vue from 'vue'
import { ActionTree, MutationTree } from 'vuex'
import { RootState } from '@/store/types'
import { AxiosResponse, AxiosError } from 'axios'
import cloneDeep from 'lodash/cloneDeep'
import { Dictionary } from 'vue-router/types/router'

interface MerchantEcpFormState {
  loaded: boolean;
  loading: boolean;
  fatalError: boolean;
  serverErrors: any;

  object: any;
  form: any;
}

const $state: MerchantEcpFormState = {
  loading: false,
  loaded: false,
  fatalError: false,
  serverErrors: null,

  object: null,
  form: null,
}


const initState = cloneDeep($state)


const EMPTY_OBJECT = {
  ecp_operator_name: null,
  ecp_phone_number: null,
  ecp_manager_name: null,
  ecp_email: null,
}

const AVAILABLE_FIELDS = [
  'ecp_operator_name',
  'ecp_phone_number',
  'ecp_manager_name',
  'ecp_email',
]

export const $actions: ActionTree<MerchantEcpFormState, RootState> = {

  initState({ commit, state }, { id, predefined }) {
    return new Promise((resolve, reject) => {
      if (id) {
        const vm = (this as any)._vm

        vm.$http
          .get(`/admins/rest/v1/merchants/${id}`)
          .then((response: AxiosResponse) => {
            commit('SET_FORM_DATA', {
              object: response.data.merchant,
            })
            commit('LOADED_SUCCESS')

            resolve(state.object)
          })
          .catch((error: AxiosError) => {
            // tslint:disable-next-line:no-console
            console.error('Fatal error', error.response?.data)
            commit('LOADED_ERROR')
            reject(error.response?.data)
          })
      }
      else {
        return new Promise((resolve, reject) => {
          commit('GENERATE_FORM_DATA', predefined)
          commit('LOADED_SUCCESS')
          resolve(state.object)
        })
      }
    })
  },

  changeFormValue({ commit }, { field, newValue }) {
    commit('CHANGE_VALUE', { field, newValue })
  },

  saveObject({ commit, state }, id) {
    return new Promise((resolve, reject) => {
      const vm = (this as any)._vm

      const cleanForm: any = {}
      for (const key of AVAILABLE_FIELDS) {
        if (state.form[key] && key !== 'contacts') {
          cleanForm[key] = state.form[key]
        }
        else if (state.form[key]) {
          cleanForm[key] = { contacts: state.form[key] }
        }
      }

      commit('START_LOADING')

      let request = null

      if (id) {
        request = vm.$http.patch(`/admins/rest/v1/merchants/${id}`, {
          merchant: cleanForm,
        })

      }
      else {
        request = vm.$http.post(`/admins/rest/v1/merchants`, {
          merchant: cleanForm,
        })
      }

      request
        .then((response: AxiosResponse) => {
          commit('LOADED_SUCCESS')

          resolve(response.data)
        })
        .catch((errorResponse: AxiosError) => {
          // eslint-disable-next-line
          console.error('Fatal error', errorResponse)

          if (errorResponse.response?.status === 500) {
            commit('LOADED_ERROR')
            reject()
          }
          else {
            const serverError = errorResponse.response?.data?.error

            commit('LOADED_SUCCESS')
            commit('SET_SERVER_ERROR', serverError)
            reject(serverError)
          }
        })
    })

  },
}

export const $mutations: MutationTree<MerchantEcpFormState> = {
  RESET_STATE: (state) => {
    for (const key in initState) {
      (state as any)[key] = (initState as any)[key]
    }
  },
  START_LOADING: (state) => {
    state.loaded = false
    state.loading = true
    state.fatalError = false
  },
  LOADED_SUCCESS: (state) => {
    state.loaded = true
    state.loading = false
    state.fatalError = false
  },
  LOADED_ERROR: (state) => {
    state.loaded = true
    state.loading = false
    state.fatalError = true
  },
  SET_SERVER_ERROR: (state, errors) => {
    state.serverErrors = errors
  },

  SET_FORM_DATA(state, { object }) {
    state.object = object

    const formData = cloneDeep(state.object)

    state.form = {
      ...formData,
    }

    state.form.requisites_actual_address_tooltip_value = {}
  },

  GENERATE_FORM_DATA(state, predefined) {
    state.form = {
      ...cloneDeep(EMPTY_OBJECT),
      ...predefined,
    }
  },

  CHANGE_VALUE(state: MerchantEcpFormState, { field, newValue }) {
    state.form[field] = newValue
  },

  ON_FORM_SAVE(state, { object }) {
    state.object = object

    const formData = cloneDeep(object)

    state.form = {
      ...formData,
    }
  },

}

const $getters = {}

export const STORE_KEY = '$_merchant_ecp_form'

export const store = {
  namespaced: true,
  state: $state,
  actions: $actions,
  getters: $getters,
  mutations: $mutations,
}

